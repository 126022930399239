@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Inter', sans-serif;
    background-color: #161923;
    /* Common background color */
    overflow-x: hidden;
    /* Prevent horizontal scroll */
}

.bg-sidebar {
    background-image: linear-gradient(to bottom, #fcac06, #f1bf73);
}
/* .bg-sidebar {
    background-image: linear-gradient(to bottom, #152328, #161923);
} */

.no-scrollbar {
    scrollbar-width: none;
}

.toast-anim {
    animation: toast-show 0.8s ease-in-out forwards;
}

@keyframes toast-show {
    0% {
        right: -200px;
    }
    100% {
        right: 24px;
    }
}

@media (max-width: 600px) {
    @keyframes toast-show {
        0% {
            right: -200px;
        }
        100% {
            right: 2px;
        }
    }
}
